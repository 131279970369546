<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Route List</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'Home' }">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Show</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="card">
        <div class="overlay" v-if="is_loading">
          <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
        <div class="card-header">
          <h3 class="card-title">Detail</h3>
          <div class="card-tools">
            <router-link class="btn btn-info" :to="{ name: 'RouteList' }">
              <i class="fas fa-list"></i> List
            </router-link>
          </div>
        </div>
        <div class="card-body table-responsive p-0">
          <table class="table table-striped">
            <tbody>
              <tr>
                <td><b>Route Name</b></td>
                <td>{{ item.route_name }}</td>
              </tr>
              <tr>
                <td><b>Path URL</b></td>
                <td>{{ item.path_url }}</td>
              </tr>
              <tr>
                <td><b>Route Controller</b></td>
                <td>{{ item.route_controller }}</td>
              </tr>
              <tr>
                <td><b>Route Model</b></td>
                <td>{{ item.route_name }}</td>
              </tr>
              <tr>
                <td><b>Created At</b></td>
                <td>
                  {{ !_.isEmpty(item.created_at) ? item.created_at : "" }}
                </td>
              </tr>
              <tr>
                <td><b>Updated At</b></td>
                <td>
                  {{ !_.isEmpty(item.updated_at) ? item.updated_at : "" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer"></div>
      </div>
    </section>
  </div>
</template>

<script>
import { RouteListAPI } from "@/services/api";

export default {
  name: "RouteListShow",
  data: () => ({
    item: {},
    is_loading: false,
  }),
  mounted: function () {
    this.itemShow(this.$route.params.id);
  },
  methods: {
    itemShow(id) {
      RouteListAPI.show(id).then((item) => {
        this.item = item;
      });
    },
  },
};
</script>
